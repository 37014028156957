import _ from 'lodash'
import axios from 'axios'
import ModaleGauche from '../../modals/modale_gauche.vue'
import LoadingBtn from '../../../common/ui/loading_btn.vue'
import FieldAdresse from '../../maps/field_adresse.vue'


export default {
	data() {
		return {
			
		}
	},
	props: [],
	watch: {
		
	},
	created(){
		
	},
	mounted(){
	},
	methods: {
		activateDrawingMode(mode) {
			if(this.$store.state.annotations.drawingMode !== mode){
				this.$store.dispatch('setAnnotationsDrawingMode', mode)
			}else{
				this.$store.dispatch('setAnnotationsDrawingMode', null)
			}
		},
		deleteAllAnnotationsElements() {
			this.$store.dispatch('deleteAllAnnotationsElements')
		}
	},
	computed:{
		
	},
	components:{
		ModaleGauche,
		LoadingBtn,
		FieldAdresse
	}
}